<template>
    <div class="page">
		
        <div v-if="loading">
			Loading...
		</div>

        <div class="menu">
            <router-link class="menu-item" v-for="(item,i) in items" :key="i" :to="item.path">
                <span class="menu-title">{{item.text}}</span>
            </router-link>
        </div>

        <div class="action-bar">
            <router-link class="btn" to="/board"><i class="fal fa-fw fa-angle-left"></i></router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "List",
    data: () => ({
        loading: false,
        items: []
    }),
    computed: {
        ...mapGetters({
            tasks: "tasks/data",
            plans: "plans/data"
        })
    },
    created() {
        this.load();
    },
    watch: {
        "$route.path"() {
            this.load();
        }
    },
    methods: {
        ...mapActions({
            listTasksThrottled: "tasks/listThrottled",
            listTasks: "tasks/list",
            listPlansThrottled: "plans/listThrottled",
            listPlans: "plans/list"
        }),
        async load() {
            this.loading = true;
            const path = this.$route.path;
            switch (path) {
                case "/tasks":
                    await this.listTasksThrottled();
                    this.items = this.tasks.list.map(x => ({
                        path: this.getPath(x),
                        text: x.name
                    }))
                    break;
                case "/plans":
                    await this.listPlansThrottled();
                    this.items = this.plans.list.map(x => ({
                        path: `/plans/${x._id}`,
                        text: x.name
                    }))
                    break;
            }
            this.loading = false;
        },
        getPath(task) {
            switch (task.type) {
                case "action":
                    return `/actions/${task._id}`
                case "sequence":
                    return `/sequences/${task._id}`
            }
            return null;
        }
    }
}
</script>